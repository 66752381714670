import { FormArray } from '@angular/forms';
import { assertInInjectionContext, inject, DestroyRef } from '@angular/core';
import { ReplaySubject } from 'rxjs';
function objectToKeyValueArray(errors) {
  const errorList = [];
  for (const key in errors) {
    if (!Object.prototype.hasOwnProperty.call(errors, key)) {
      continue;
    }
    errorList.push({
      key: key,
      value: errors[key]
    });
  }
  return errorList;
}
function isInViewport(element) {
  const bounding = element.getBoundingClientRect();
  return bounding.top >= 0 && bounding.left >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) && bounding.right <= (window.innerWidth || document.documentElement.clientWidth);
}
function replaceAll(val, searchValue, replaceValue) {
  // .replace interprets searchValue as a string and will only replace the first occurnce.
  // .replaceAll was added in ECMA-262 (2021) but is not currently available in nodeJS.
  // https://stackoverflow.com/questions/1144783/how-to-replace-all-occurrences-of-a-string-in-javascript/54414146
  // Solutions that do not work properly
  // - while indexOf() > -1: Lets somehow crash chrome during tests
  // - new Regex(searchValue, 'g'): Does not work because the dot and other characters would need to be escaped first
  // - Escaping searchValue and then new Regex(...): Does not look like a proper solution either
  val = val.split(searchValue).join(replaceValue);
  return val;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class AutoFormArray extends FormArray {
  controlGenerator;
  /**
   * Creates a new `FormArray` instance.
   *
   * @param controlGenerator A function that generates the control for en entry.
   *
   * @param validatorOrOpts A synchronous validator function, or an array of
   * such functions, or an `AbstractControlOptions` object that contains validation functions
   * and a validation trigger.
   *
   * @param asyncValidator A single async validator or array of async validator functions
   *
   */
  constructor(controlGenerator, validatorOrOpts, asyncValidator) {
    super([], validatorOrOpts, asyncValidator);
    this.controlGenerator = controlGenerator;
  }
  patchValue(...args) {
    this.resizeTo(args[0] ? args[0].length : 0);
    return super.patchValue(...args);
  }
  reset(...args) {
    this.resizeTo(args[0] ? args[0].length : 0);
    return super.reset(...args);
  }
  resizeTo(length) {
    while (this.length < length) {
      const newForm = this.controlGenerator();
      if (this.disabled) {
        newForm.disable();
      }
      this.push(newForm);
    }
    while (this.length > length) {
      this.removeAt(this.length - 1);
    }
  }
}

/**
 * Injects the `DestroyRef` service and returns a `ReplaySubject` that emits
 * when the component is destroyed.
 *
 * @throws {Error} If no `DestroyRef` is found.
 * @returns {ReplaySubject<void>} A `ReplaySubject` that emits when the component is destroyed.
 *
 * @example
 * // In your component:
 * export class MyComponent {
 *   private destroy$ = injectDestroy();
 *
 *   getData() {
 *     return this.service.getData()
 *       .pipe(takeUntil(this.destroy$))
 *       .subscribe(data => { ... });
 *   }
 * }
 */
const injectDestroy = () => {
  assertInInjectionContext(injectDestroy);
  const destroyRef = inject(DestroyRef);
  const subject$ = new ReplaySubject(1);
  destroyRef.onDestroy(() => {
    subject$.next();
    subject$.complete();
  });
  return subject$;
};

// export what ./public_api exports so we can import with the lib name like this:
// import { ModuleA } from 'libname'

/**
 * Generated bundle index. Do not edit.
 */

export { AutoFormArray, injectDestroy, isInViewport, objectToKeyValueArray, replaceAll };
